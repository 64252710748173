<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-22 18:14:02
 * @LastEditTime: 2023-03-22 20:58:59
 * @Descripttion: 授课班级管理
-->
<style lang="scss" scoped>
.teaching-class {
    @include innerPage;
    @include pageHead(-10px);
    @include defalutTable(calc(100% - 162px), 38px);
    @include pageFoot;

    .page-head {
        .head-wrapper.column {
            margin-top: -24px;

            .wrap-plate {
                box-sizing: border-box;
                padding-bottom: 12px;
                margin-top: 0;
            }

            .num-box {
                width: 32%;
                max-width: 464px;
                min-width: 440px;
                height: 90px;
                @include flexBox(space-between);

                li {
                    width: calc((100% - 10px) / 2);
                    height: 100%;
                    border-radius: 10px;
                    box-sizing: border-box;
                    padding: 0 26px 0 30px;
                    @include flexBox(space-between);

                    .statistics-info {
                        color: #fff;
                        flex-direction: column;
                        line-height: 24px;
                        @include flexBox(center, flex-start);

                        .pf_bold {
                            font-size: 30px;
                            line-height: 40px;

                            .pf {
                                font-size: 14px;
                                margin-left: 2px;
                            }
                        }
                    }

                    .icon-box {
                        width: 66px;
                        height: 66px;
                        border-radius: 20px;
                        @include flexBox(center)
                    }

                    &:nth-child(1) {
                        @include dotBgOrangered;

                        .icon-box {
                            background: rgba($color: #e07263, $alpha: 1.0);
                            box-shadow: 0px 0px 57px 0px rgba(141, 17, 0, 0.5);
                        }
                    }

                    &:nth-child(2) {
                        @include dotBgGreen;

                        .icon-box {
                            background: rgba($color: #4cb76a, $alpha: 1.0);
                            box-shadow: 0px 0px 57px 0px rgba(2, 101, 31, 0.6);
                        }
                    }
                }
            }

            .plate--right {
                flex-grow: 1;
                box-sizing: border-box;
                padding-top: 16px;
                margin-left: 20px;
                @include flexBox;
            }

            .search-form {
                width: 60%;
                max-width: 560px;
                min-width: 360px;
                margin-left: auto;
                margin-right: 2.8%;

                .el-form {
                    flex-wrap: nowrap;
                }

                .el-form-item {
                    max-width: 200px;
                }
            }

            .waite-deal {
                margin: 0 20px;

                .el-button {
                    color: #6340c8;
                    background: #d3d3d3;
                    border-color: #d3d3d3;

                    &:hover {
                        color: #8971d5;
                        background: #d9d9d9;
                        border-color: #d9d9d9;
                    }
                }
            }

        }
    }
}

.dialog-container {
    box-sizing: border-box;
    padding: 36px 0;

    &--detail {
        max-height: 480px;
        overflow: hidden;
        overflow-y: auto;

        h5 {
            line-height: 30px;
            color: #6340c8;
            margin-bottom: 14px;
        }

        .student-info,
        .parents {
            box-sizing: border-box;
            padding: 0 12px;
        }

        .parents {
            padding-top: 20px;
            margin-top: 28px;
            border-top: 1px solid #e8e8e8;

            &-list {
                width: 100%;
                background: #f6f7fc;
                border-radius: 10px;
                box-sizing: border-box;
                padding: 16px 28px;
                flex-wrap: wrap;
                @include flexBox;

                .parent {
                    width: 50%;
                    font-size: 16px;
                    margin-bottom: 16px;
                    @include flexBox(center);

                    .relation {
                        display: inline-block;
                        width: 62px;
                        height: 62px;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 62px;
                        color: #fff;
                        background: rgba($color: #b3b3b3, $alpha: .43);
                        flex-shrink: 0;
                    }

                    &-info {
                        margin-left: 18px;
                        flex-direction: column;
                        color: #252525;
                        line-height: 24px;
                        @include flexBox(center, flex-start);
                    }

                    &.father .relation {
                        background: rgba($color: #6340c8, $alpha: .43);
                    }

                    &.mather .relation {
                        background: rgba($color: #ee4f65, $alpha: .43);
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

            }
        }

        .student-detail,
        .parents-list {
            p {
                width: 760px;
                min-height: 30px;
                box-sizing: border-box;
                padding: 3px 0;
                color: #484848;
                @include flexBox(flex-start, flex-start);

                &.avatar {
                    width: 100%;
                    height: 72px;
                    align-items: center;
                }

                span {
                    display: inline-block;
                    line-height: 24px;

                    &.label {
                        width: 82px;
                        padding-right: 20px;
                        text-align: right;
                        box-sizing: border-box;
                    }

                    &.failed {
                        color: #aeaeae;
                    }

                    &.primary {
                        color: #f8c436;
                    }

                    &.success {
                        color: #3bc8af;
                    }
                }
            }
        }

        .student-detail {
            flex-wrap: wrap;
            @include flexBox;

            p {
                width: 50%;
                color: #1b1b1b;

                span.label {
                    width: 90px;
                }
            }
        }
    }

    &--student {
        box-sizing: border-box;
        padding: 22px 0;

        .el-avatar {
            margin-left: 84px;
            margin-bottom: 20px;
        }

        .el-form {
            justify-content: space-between;
        }

        .el-form-item {
            width: 444px;
            margin-bottom: 20px;

            ::v-deep {
                .el-date-editor .el-input__inner {
                    padding: 0 28px;
                }
            }
        }
    }
}
</style>

<template>
    <section class="teaching-class">
        <div class="page-head">
            <img class="bitmap" src="@assets/images/bitmap-head.png" alt="">
            <div class="head-wrapper column">
                <breadcrumb />
                <div class="wrap-plate">
                    <ul class="num-box">
                        <li>
                            <div class="statistics-info">
                                <p class="pf_bold">{{ statisticalNum.count_user || 0 }}</p>
                                <p class="light">全班总人数</p>
                            </div>
                            <p class="icon-box">
                                <img src="@assets/images/student-icon1.png" alt="">
                            </p>
                        </li>
                        <li>
                            <div class="statistics-info">
                                <p class="pf_bold">{{ statisticalNum.buy_yes || 0 }}</p>
                                <p class="light">手写板数量</p>
                            </div>
                            <p class="icon-box">
                                <img src="@assets/images/student-icon2.png" alt="">
                            </p>
                        </li>
                    </ul>
                    <div class="plate--right">
                        <div class="search-form">
                            <el-form inline>
                                <el-form-item>
                                    <el-select v-model="searchForm.stuser_accstatus" placeholder="请选择账号状态" clearable>
                                        <el-option label="启用" :value="10" />
                                        <el-option label="禁用" :value="20" />
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <el-input v-model.trim="searchForm.stuser_name" maxlength="100" placeholder="输入学生姓名"
                                        clearable>
                                        <span slot="prefix" class="iconfont">&#xe61c;</span>
                                    </el-input>
                                </el-form-item>
                            </el-form>
                            <el-button type="custom_primary" size="medium" @click="searchData">查询</el-button>
                        </div>
                        <el-badge v-if="isClassTeacher" :value="statisticalNum.count_examine" :max="99" class="waite-deal"
                            :hidden="statisticalNum.count_examine == 0">
                            <el-button type="custom_info" size="medium"
                                @click="$router.push({ name: 'HOME_STUDENTTRANSFER', query: { id: $route.params.class } })">
                                待接收处理
                            </el-button>
                        </el-badge>
                        <el-button type="custom_primary" size="medium" @click="$router.go(-1)">
                            返回
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="table-box">
            <div class="table-inner" ref="tableInner">
                <el-table :data="tableData" height="100%">
                    <el-table-column align="center" label="头像" min-width="13.25%">
                        <template slot-scope="scope">
                            <p class="table-img">
                                <el-avatar fit="cover" :size="56" :src="formatfile(scope.row.stuser_image)" class="avatar">
                                    <img src="@assets/images/empty_avatar.png" />
                                </el-avatar>
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="stuser_name" label="姓名 " min-width="15%" />
                    <el-table-column align="center" label="性别" min-width="10%">
                        <template slot-scope="scope">
                            {{ scope.row.stuser_sex == 10 && '男' || '女' }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="stuser_stuno" label="学号" min-width="15%" />
                    <el-table-column align="center" prop="parent_names" label="家长" min-width="20%" />
                    <el-table-column align="center" label="账号状态" min-width="8.41%">
                        <template slot-scope="scope">
                            <p class="table-status"
                                :class="{ failed: scope.row.stuser_accstatus == 20, success: scope.row.stuser_accstatus == 10 }">
                                {{ scope.row.stuser_accstatus == 10 && '启用' || '禁用' }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" min-width="18.34%">
                        <template slot-scope="scope">
                            <template v-if="isClassTeacher">
                                <el-button type="custom_warning" size="mini" plain  @click="resetPwd(scope.row.stuser_id)">重置密码</el-button>
                                <el-button type="custom_primary" size="mini" plain v-loading="btnloading" @click="getStudent(scope.row.stuser_id, 'e')">编辑</el-button>
                            </template>
                            <el-button type="custom_success" v-loading="btnloading" size="mini" plain @click="getStudent(scope.row.stuser_id, 'd')">详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="table-foot custom-foot">
                <customPagination :current='tablePage.pageIndex' :total="tablePage.total" @pageChange="flippingPage" />
            </div>
        </div>
        <!-- 编辑信息 -->
        <el-dialog title="编辑信息" width="1034px" :visible.sync="studentDialog" :show-close="false" @close="resetForm">
            <div class="dialog-container--student">
                <el-avatar fit="cover" :size="84" :src="formatfile(studentDetail.stuser_image)" class="avatar">
                    <img src="@assets/images/empty_avatar.png" />
                </el-avatar>
                <el-form label-width="82px" ref="studentForm" inline :model="studentForm" :rules="rules">
                    <el-form-item label="学生姓名" prop="stuser_name">
                        <el-input v-model.trim="studentForm.stuser_name" maxlength="100" placeholder="请输入" />
                    </el-form-item>
                    <el-form-item label="性别">
                        <el-input v-model.trim="studentDetail.stuser_sex" readonly />
                    </el-form-item>
                    <el-form-item label="账号">
                        <el-input v-model.trim="studentDetail.stuser_account" readonly />
                    </el-form-item>
                    <el-form-item label="学号">
                        <el-input v-model.trim="studentDetail.stuser_stuno" readonly />
                    </el-form-item>
                    <el-form-item label="地区" prop="area">
                        <el-cascader v-model="studentForm.area" :options="province"
                            :props="{ value: 'ddi_id', label: 'ddi_name' }" placeholder="请选择地区" />
                    </el-form-item>
                    <el-form-item label="班级">
                        <el-input v-model.trim="studentDetail.class_info" readonly />
                    </el-form-item>
                    <el-form-item label="学生状态">
                        <el-input v-model.trim="studentDetail.stuser_readstatus" placeholder="请选择" readonly />
                    </el-form-item>
                    <el-form-item label="入学时间" prop="stuser_entertime">
                        <el-date-picker v-model="studentForm.stuser_entertime" type="date" placeholder="选择入学时间"
                            format="yyyy年M月d日" prefix-icon="none" />
                    </el-form-item>
                    <el-form-item label="账号状态">
                        <el-switch v-model="studentForm.stuser_accstatus" :active-value="10" :inactive-value="20"
                            :active-text="studentForm.stuser_accstatus == 10 && '启用' || '禁用'" />
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer">
                <el-button type="custom_info" size="small" @click="resetForm">
                    取 消
                </el-button>
                <el-button type="custom_primary" size="small" @click="editStudent" :disabled="btnload" v-loading="btnload">
                    确 定
                </el-button>
            </div>
        </el-dialog>
        <!-- 学生详情 -->
        <el-dialog title="学生详情" width="1034px" :visible.sync="detailDialog">
            <div class="dialog-container">
                <div class="dialog-container--detail">
                    <div class="student-info">
                        <h5>学生信息</h5>
                        <div class="student-detail">
                            <p class="avatar">
                                <span class="label">头像：</span>
                                <span class="value">
                                    <el-avatar fit="cover" :size="56" :src="formatfile(studentDetail.stuser_image)"
                                        class="avatar">
                                        <img src="@assets/images/empty_avatar.png" />
                                    </el-avatar>
                                </span>
                            </p>
                            <p>
                                <span class="label">学生姓名：</span>
                                <span class="value">{{ studentDetail.stuser_name }}</span>
                            </p>
                            <p>
                                <span class="label">性别：</span>
                                <span class="value">{{ studentDetail.stuser_sex == 10 && '男' || '女' }}</span>
                            </p>
                            <p>
                                <span class="label">账号：</span>
                                <span class="value">{{ studentDetail.stuser_account }}</span>
                            </p>
                            <p>
                                <span class="label">账号状态：</span>
                                <span class="value">{{ studentDetail.stuser_accstatus == 10 && '启用' || '禁用' }}</span>
                            </p>
                            <p>
                                <span class="label">学号：</span>
                                <span class="value">{{ studentDetail.stuser_stuno }}</span>
                            </p>
                            <p>
                                <span class="label">学生状态：</span>
                                <span class="value">{{ studentDetail.stuser_readstatus == 10 && '在读' || '休学' }}</span>
                            </p>
                            <p>
                                <span class="label">入学时间：</span>
                                <span class="value">{{ studentDetail.stuser_entertime | formatTime('YYYY年M月D日') }}</span>
                            </p>
                            <p>
                                <span class="label">地区：</span>
                                <span class="value">{{ studentDetail.stuser_province_city_area }}</span>
                            </p>
                        </div>
                    </div>
                    <div class="parents" v-if="!$isEmpty(studentDetail.parent_data)">
                        <h5>家长信息</h5>
                        <ul class="parents-list">
                            <li class="parent father"
                                :class="{ father: item.pauser_name == '爸爸', mather: item.pauser_name == '妈妈' }"
                                v-for="item in studentDetail.parent_data" :key="item.pauser_id">
                                <span class="relation bold">{{ item.pauser_stuser_parentage }}</span>
                                <p class="parent-info">
                                    <span class="bold">{{ item.pauser_name }}</span>
                                    <span class="pf">{{ item.pauser_phone }}</span>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import { $studentList, $studentDetail, $studentEdit, $studentResetPwd } from "@api/home"
import { formatFile, formatTimeStamp } from "@utils"
import { mapState } from "vuex"
import customPagination from "@comp/customPagination"

export default {
    name: 'home_teachingClass',
    components: { customPagination },
    computed: {
        ...mapState('common', ['province']),
        formatfile() {
            return function (url) {
                return formatFile(url)
            }
        }
    },
    data() {
        return {
            searchForm: {}, // 筛选表单
            tableData: [], // 表格数据
            /* 分页 */
            tablePage: {
                pageIndex: 1,
                total: 0
            },
            /* 统计数据 */
            statisticalNum: {
                count_user: 0,
                buy_yes: 0,
                count_examine: 0
            },
            defalutPwd: '', // 默认密码
            studentDialog: false, // 显示学生编辑弹窗
            detailDialog: false, // 显示学生详情弹窗
            studentDetail: {}, // 学生详情
            studentForm: {}, // 学生信息表单
            btnload: false,
            isClassTeacher: false, // 是否为班主任
            rules: {
                stuser_name: [{ required: true, message: '请输入学生姓名', trigger: 'blur' }],
                area: [{ required: true, message: '请选择学生所在地区', trigger: 'change', type: 'array' }],
                stuser_entertime: [{ required: true, message: '请选择学生入学时间', trigger: 'change' }],
            },
            //点击按钮等待
            btnloading:false,
        }
    },
    created() {
        this.getData();
    },
    methods: {
        /** 获取学生列表 */
        async getData() {
            let { grade: sccla_grade, class: sccla_id } = this.$route.params
            let params = {
                sccla_grade, sccla_id,
                pageindex: this.tablePage.pageIndex,
                ...this.searchForm
            }
            let { data: res } = await $studentList(params);
            let { count_user, buy_yes, count_examine, default_password: defalutPwd } = res;
            this.tablePage.total = res.allcount;
            this.tableData = res.data;
            this.statisticalNum = { count_user, buy_yes, count_examine };
            this.defalutPwd = defalutPwd;
            this.isClassTeacher = res.is_identity == 10 && true || false;
            this.$forceUpdate();
        },
        /**
         * 重置学生账号密码
         * @param {number} id 学生id
         */
        async resetPwd(id) {
            this.$msgbox({
                title: '提示',
                message: `确认重置当前学生账号密码为${this.defalutPwd}`,
                type: 'info',
                showClose: false,
                showCancelButton: true,
                cancelButtonClass: 'el-button--custom_info',
                confirmButtonClass: 'el-button--custom_primary',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(async () => {
                let res = await $studentResetPwd(id);
                if (res) {
                    this.$message({
                        type: 'success',
                        duration: 1500,
                        message: '当前学生账号密码已重置，请及时通知！'
                    });
                }

            }).catch(() => {
                this.$message({
                    type: 'info',
                    duration: 1500,
                    message: '操作已取消'
                })
            });
        },
        /** 修改学生信息 */
        editStudent() {
            this.$refs.studentForm.validate(async (valid) => {
                if (valid) {
                    this.btnload = true;
                    let { area, stuser_entertime } = this.studentForm;
                    let params = {
                        ...this.studentForm,
                        stuser_province_id: area[0],
                        stuser_city_id: area[1],
                        stuser_area_id: area[2],
                        stuser_entertime: formatTimeStamp(stuser_entertime)
                    }
                    delete params.area;
                    let res = await $studentEdit(params);
                    this.btnload = false;
                    if (res) {
                        this.resetForm();
                        this.$message.success('编辑成功');
                        this.getData();
                    }
                }
            });
        },
        /**
         * 学生详情
         * @param {number} id 学生id
         */
        async getStudent(id, type) {
            this.btnloading=true
            let { data } = await $studentDetail(id);
            this.studentDetail = data;
            if (type == 'd') {
                this.detailDialog = true;
            } else {
                this.studentDetail.stuser_sex = data.stuser_sex == 10 && '男' || '女';
                this.studentDetail.stuser_readstatus = data.stuser_readstatus == 10 && '在读' || '未读';
                this.studentForm = {
                    stuser_id: data.stuser_id,
                    stuser_name: data.stuser_name,
                    area: [data.stuser_province_id, data.stuser_city_id, data.stuser_area_id],
                    stuser_entertime: data.stuser_entertime,
                    stuser_accstatus: data.stuser_accstatus,
                }
                this.studentDialog = true;
            }
            this.btnloading=false
        },
        /** 搜索 */
        searchData() {
            this.tableData = [];
            this.tablePage.pageIndex = 1;
            this.getData();
        },
        /** 翻页 */
        flippingPage(val) {
            this.tablePage.pageIndex = val;
            this.getData();
        },
        /** 重置表单，关闭弹窗 */
        resetForm() {
            this.studentDialog = false;
            this.$refs['studentForm'].resetFields();
            this.studentForm = {};
        }
    },
}
</script>